import React from 'react';

import './App.css';

import cows_scaled from './images/cows-scaled.jpg'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={cows_scaled} alt="cows" className="rounded-lg object-cover object-center"/>
      </header>
    </div>
  );
}

export default App;